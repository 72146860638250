import React from 'react';
import { Badge } from 'react-bootstrap';

function ClientColorBadge({ color }) {
  let bgColor = '';

  switch (color) {
    case 'yellow':
      bgColor = 'warning';
      break;

    case 'blue':
      bgColor = 'primary';
      break;

    case 'green':
      bgColor = 'success';
      break;

    case 'grey':
      bgColor = 'secondary';
      break;

    default:
      bgColor = 'light';
      break;
  }

  return (
    <Badge className="text-uppercase" bg={bgColor}>
      {color}
    </Badge>
  );
}

export default ClientColorBadge;
