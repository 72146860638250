import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  tableFooterClassName,
  footerGroups,
  totalColumnsWidth,
  selectedFlatRows,
  virtualRows = false,
  virtualRowsTableOffsetHeight = `30rem`,
  rowProps = row => {},
  SelectedRowsTableFooterRow = selectedFlatRows => {},
  renderRowSubComponent = arg => <></>,
  useTableFooter,
  apiCallInProcess = false,
  loadingText = 'Loading...',
  customNoResultFoundMessage = ''
}) => {
  const TableJSX = () => (
    <Table {...getTableProps(tableProps)}>
      <thead
        className={`${
          virtualRows ? `sticky-top shadow` : ''
        } ${headerClassName}`}
      >
        <tr>
          {headers.map((column, index) => (
            <th key={index} {...column.getHeaderProps(column.headerProps)}>
              {column.canFilter ? column.render('Filter') : null}
              {column.render('Header')}
              <span {...column.getSortByToggleProps()}>
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc ms-1" />
                    ) : (
                      <span className="sort asc ms-1" />
                    )
                  ) : (
                    <span className="sort ms-1" />
                  )
                ) : (
                  ''
                )}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {apiCallInProcess ? (
          <tr>
            <td colSpan={headers.length} className="text-center">
              <div>
                <FontAwesomeIcon icon={'spinner'} spin />
                <span className="ms-2">{loadingText}</span>
              </div>
            </td>
          </tr>
        ) : page.length == 0 ? (
          <tr>
            <td colSpan={headers.length} className="text-center">
              <div>
                <span className="ms-2">
                  {customNoResultFoundMessage || 'No Results found'}
                </span>
              </div>
            </td>
          </tr>
        ) : (
          page.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr
                  className={rowClassName}
                  {...row.getRowProps(rowProps(row))}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={headers.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })
        )}
      </tbody>
      {useTableFooter && (
        <tfoot
          className={`${
            virtualRows ? `sticky-bottom shadow` : ''
          } ${tableFooterClassName}`}
        >
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <td {...column.getFooterProps(column.footerProps)}>
                  {column.render('Footer')}
                </td>
              ))}
            </tr>
          ))}
          {SelectedRowsTableFooterRow(selectedFlatRows)}
        </tfoot>
      )}
    </Table>
  );

  return (
    <>
      {virtualRows ? (
        <div
          className="virtualized-rows-wrapper"
          style={{
            height: `calc(100vh - ${virtualRowsTableOffsetHeight})`,
            minHeight: `300px`
          }}
        >
          <TableJSX />
        </div>
      ) : (
        <SimpleBarReact>
          <TableJSX />
        </SimpleBarReact>
      )}
    </>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  apiCallInProcess: PropTypes.bool
};

export default AdvanceTable;
