import CallApi from 'components/common/custom-components/CallApi';
import { AidaAppContext } from 'context/AidaAppContext';
import { setItemToStore } from 'helpers/utils';
import useAuthActions from 'hooks/useAuthActions';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const LoginForm = ({
  hasLabel,
  layout,
  setUsers = arg => {},
  setSudoUser = arg => {},
  setShowImpersonationCard = arg => {},
  showPasswordResetForm = () => {},
  ...rest
}) => {
  const authActions = useAuthActions();

  const aidaAppContext = useContext(AidaAppContext);

  const { setAidaAppContext } = aidaAppContext;

  // State
  const [showAuthenticatorCodeField, setShowAuthenticatorCodeField] =
    useState(false);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    remember: false,
    totp_code: '',
    device_name: 'Web App'
  });

  const LoginMutation = useMutation(
    'login',
    payload => CallApi.post('/login', payload),
    {
      onSuccess: json => {
        if (json.user_role == 'Sudo Admin') {
          setUsers(json?.users);
          setSudoUser({ name: json?.sudo_user_name, token: json?.token });
          setShowImpersonationCard(true);
          setAidaAppContext({
            ...aidaAppContext,
            user_role: json.user_role
          });
        } else if (json?.totp_code_required) {
          setShowAuthenticatorCodeField(true);
        } else if (json.status == 200) {
          setItemToStore('aidaToken', json.token);

          if (json?.force_to_change_password) {
            showPasswordResetForm();
          } else {
            authActions.runPostLoginActions();
          }

          toast.success('Logged In successfully.');
        }
      }
    }
  );

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    let payload = new FormData();
    for (const field in formData) {
      if (Object.hasOwnProperty.call(formData, field)) {
        const value = formData[field];
        payload.append(field, value);
      }
    }
    LoginMutation.mutate(payload);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div {...rest}>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Email address</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Username' : ''}
            value={formData.username}
            name="username"
            onChange={handleFieldChange}
            type="username"
            autoComplete="username"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Row className="justify-content-between align-items-center">
            <Col xs="auto">{hasLabel && <Form.Label>Password</Form.Label>}</Col>
            <Col xs="auto">
              <Link
                className="fs--1 fw-normal mb-0"
                to={`/${layout}/forgot-password`}
              >
                Forgot Password?
              </Link>
            </Col>
          </Row>
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            autoComplete="current-password"
            type="password"
            required
          />
        </Form.Group>

        {showAuthenticatorCodeField ? (
          <Form.Group className="mb-3">
            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                {hasLabel && <Form.Label>Enter Authenticator Code</Form.Label>}
              </Col>
            </Row>
            <Form.Control
              placeholder={!hasLabel ? 'Enter Authenticator Code' : ''}
              value={formData.totp_code}
              name="totp_code"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>
        ) : null}
        {/* <Row className="justify-content-between align-items-center">
            <Col xs="auto">
              <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="ms-2 mb-0">
              Remember Me
            </Form.Check.Label>
          </Form.Check>
            </Col>

            <Col xs="auto">
              <Link
                className="fs--1 mb-0"
                to={`/authentication/${layout}/forgot-password`}
              >
                Forgot Password?
              </Link>
            </Col>
          </Row> */}

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 rounded-pill w-100"
            disabled={LoginMutation.isLoading}
          >
            {LoginMutation?.isLoading ? 'Authenticating...' : 'Log in'}
          </Button>
        </Form.Group>

        {/* <Divider className="mt-4">or log in with</Divider> */}

        {/* <SocialAuthButtons /> */}
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
