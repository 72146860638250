import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ManPullingAnotherMan } from 'assets/img/accounts/images';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useContext, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import ManualAddCompetitorModal from '../wizard-sub-forms/ManualAddCompetitorModal';
import { AdsAccountContext } from './AdsAccountContext';
import LocalStoragePageInfo from 'components/common/custom-components/LocalStoragePageInfo';
import Flex from 'components/common/Flex';

const ResearchCompetitor = () => {
  const columns = [
    {
      accessor: 'domain',
      Header: 'Domain',
      Cell: ({ cell }) => (
        <a
          href={`https://${cell.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}
        </a>
      )
    },
    {
      accessor: 'common_keywords',
      Header: 'Common Keyword',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'total_keywords',
      Header: 'Total Keyword',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'relevance',
      Header: 'Relevance',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'points',
      Header: 'Points',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  ];
  const [initiallySelectedRowIds, setInitiallySelectedRowIds] = useState({});
  const [competitors, setCompetitors] = useState([]);
  const adsAccountContext = useContext(AdsAccountContext);

  const { currentAccount, refetchCurrentAccount } = adsAccountContext;

  const CompetitorsQuery = useQuery(
    ['competitors', currentAccount?.country_id, currentAccount?.website],
    () =>
      CallApi.get(
        `/competitors/se-ranking-reports/competitors?country_id=${currentAccount?.country_id}&domain=${currentAccount?.website}`,
        true
      ),
    {
      onSuccess: json => {
        const draftedCompetitors = currentAccount?.competitors || '';

        const savedCompetitors =
          draftedCompetitors
            ?.replaceAll('"', '')
            ?.replaceAll('\\/', '/')
            ?.split(',')
            ?.filter(item => item != '') || [];

        const savedJsonCompetitors =
          savedCompetitors.map(
            competitorDomain =>
              json.data.find(
                competitor => competitor.domain == competitorDomain
              ) || {
                domain: competitorDomain,
                common_keywords: '-',
                total_keywords: '-',
                relevance: '-',
                points: '-'
              }
          ) || [];

        const allCompetitors = [
          ...savedJsonCompetitors,
          ...json.data.filter(item => !savedCompetitors.includes(item?.domain))
        ];

        let initialSelectedRowIndexes = {};

        const indexesOfPreviouslySelectedRows = allCompetitors
          ?.map((competitor, i) =>
            savedCompetitors.includes(competitor?.domain) ? i : null
          )
          ?.filter(index => index != null);

        indexesOfPreviouslySelectedRows.forEach(index => {
          initialSelectedRowIndexes[index] = true;
        });

        setInitiallySelectedRowIds(initialSelectedRowIndexes);

        setCompetitors(allCompetitors);
      },
      enabled: !!(currentAccount?.country_id && currentAccount?.website)
    }
  );

  const UpdateCompetitorsMutation = useMutation(
    ['account-competitors', currentAccount?.id],
    formData =>
      CallApi.post(`/account/${currentAccount?.id}/competitors`, formData),
    {
      onSuccess: json => {
        refetchCurrentAccount();
      }
    }
  );

  const apiCallInProcess =
    CompetitorsQuery.isLoading || CompetitorsQuery.isFetching;

  const UpdateCompetitorsButton = ({ selectedFlatRows }) => {
    return (
      <IconButton
        variant="outline-success"
        className="ms-2 rounded-pill"
        disabled={apiCallInProcess || UpdateCompetitorsMutation.isLoading}
        size="sm"
        spin={UpdateCompetitorsMutation.isLoading}
        onClick={() => {
          let formData = new FormData();

          formData.append(
            'competitors',
            selectedFlatRows.map(row => row.original.domain).join(',')
          );

          UpdateCompetitorsMutation.mutate(formData);
        }}
        icon={UpdateCompetitorsMutation.isLoading ? 'spinner' : 'check'}
      >
        Update Competitors
      </IconButton>
    );
  };
  return (
    <>
      <LocalStoragePageInfo slug="research_competitor">
        <Row className="text-dark">
          <Col>
            <Flex
              className="h-100 px-4 py-2"
              justifyContent="between"
              direction="column"
            >
              <div>
                <h2 className="mb-0 font-ibm-plex-serif">
                  Research Competitor
                </h2>
                <p className="fs-1 mt-1">
                  Specify a domain to analyze how the
                  <br /> domain performs organically
                  <br />
                  over time + research compeditor sites <br />
                  and their keywords.
                </p>
              </div>
            </Flex>
          </Col>
          <Col lg={4} xxl={3}>
            <img src={ManPullingAnotherMan} className="w-100" />
          </Col>
        </Row>
      </LocalStoragePageInfo>
      <Card>
        <Card.Body className="m-3">
          <AdvanceTableWrapper
            columns={columns}
            data={competitors || []}
            sortable
            pagination
            perPage={25}
            selection
            initialSelectedRowIds={initiallySelectedRowIds}
          >
            <Row className="align-items-center mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <ManualAddCompetitorModal
                  disabled={apiCallInProcess}
                  setCompetitors={setCompetitors}
                  competitors={competitors}
                  setInitiallySelectedRowIds={setInitiallySelectedRowIds}
                  variant="outline-primary"
                  className="rounded-pill"
                  table
                />
                <UpdateCompetitorsButton table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              loadingText={'Please wait, we are fetching your competitors...'}
              apiCallInProcess={apiCallInProcess}
            />
            <AdvanceTableFooter
              rowsPerPageOptions={[25, 50, 75, 100]}
              table
              className="mt-3 mx-3"
              rowCount={competitors?.length}
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default ResearchCompetitor;
