import React from 'react';
import IconButton from 'components/common/IconButton';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExampleCompanyDescriptionModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <span
        className="px-2 cursor-pointer text-primary"
        onClick={() => setShow(true)}
      >
        Example
      </span>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Company Description</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <h4>What is a Company Description?</h4>
          <p>
            A company description is a brief overview of your business that
            provides key information such as your products or services, target
            audience, unique selling points, and overall goals. The goal is to
            give "Trakk" enough context to generate high-quality content for
            your website.
          </p>
          What you can include:
          <ul>
            <li>
              What the company does: A short and clear description of the
              company's core business.
            </li>
            <li>
              Products or services: Describe which products or services you
              offer.
            </li>
            <li>
              Target group: Who are your customers? B2B, B2C or specific
              industries or markets?
            </li>
            <li>
              The company's unique values: What makes your company unique or
              different from the competition?
            </li>
            <li>
              Company history or goals: A brief description of the company's
              background or vision, if relevant.
            </li>
          </ul>
          <h5>Example 1:</h5>
          <p>
            "We are an e-commerce company that specializes in sustainable
            clothing and accessories. Our products are made from organic and
            recycled materials, and we primarily target environmentally
            conscious consumers. We strive to reduce the environmental impact of
            the fashion industry through innovative and sustainable solutions."
          </p>
          <h5>Example 2:</h5>
          <p>
            "Our company develops and sells modern, cloud-based accounting
            systems for small businesses. With user-friendly tools that automate
            invoicing, accounting and tax reporting, we help companies
            streamline their administrative processes. We target small and
            medium-sized companies in all industries who are looking for simple
            solutions for their economy."
          </p>
          <h5>Example 3:</h5>
          <p>
            "We are a full-service marketing agency that helps companies build
            their online presence through strategy, content marketing and
            digital advertising. We work with clients in various industries and
            focus on data-driven solutions to create long-term growth."
          </p>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExampleCompanyDescriptionModal;
