import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

const AidaDatePicker = ({
  date,
  setDate = date => {},
  placeholder = '',
  selectsRange = false,
  endDate = new Date(),
  setDateRange = updatedDateRange => {},
  name = '',
  className = '',
  ...rest
}) => {
  return (
    <>
      {selectsRange ? (
        <ReactDatePicker
          dateFormat="yyyy-MM-dd"
          selectsRange={selectsRange}
          startDate={date}
          endDate={endDate}
          onChange={update => {
            setDateRange(update);
          }}
          className="form-control pb-0 pt-1"
          calendarStartDay={1}
          {...rest}
        />
      ) : (
        <ReactDatePicker
          dateFormat="yyyy-MM-dd"
          selected={date}
          onChange={date => setDate(date)}
          calendarStartDay={1}
          className={`form-control ${className}`}
          name={name || 'date'}
          placeholderText={placeholder || 'Select Date'}
          required
          {...rest}
        />
      )}
    </>
  );
};

export default AidaDatePicker;
