import { AidaAppContext } from 'context/AidaAppContext';
import { getItemFromStore, deleteItemFromStore } from 'helpers/utils';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

const useAuthActions = () => {
  const history = useHistory();
  const aidaAppContext = useContext(AidaAppContext);

  const { refetchContext } = aidaAppContext;

  return {
    runPostLoginActions: () => {
      refetchContext();

      const redirectionUrl = getItemFromStore('redirectionUrl', false);
      deleteItemFromStore('redirectionUrl');

      if (redirectionUrl) {
        history.push(redirectionUrl);
      } else {
        history.push('/');
      }
    }
  };
};

export default useAuthActions;
