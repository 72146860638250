import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { useContext } from 'react';
import { Form, Button, Modal, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { searchQueryKeywordsContext } from '../SearchQueryKeywords';
import ChangeCampaignAdgroupTable from './ChangeCampaignAdgroupTable';
import { Flip } from 'react-toastify';
import CallApi from 'components/common/custom-components/CallApi';

const SearchQueryBulkSelect = ({
  selectedRowIds,
  page,
  resetGlobalFilterToLastQuery,
  data,
  setData,
  className
}) => {
  const [showModal, setShowModal] = useState(false);
  const { searchQueryContext, setSearchQueryContext } = useContext(
    searchQueryKeywordsContext
  );
  const BulkSelectModal = ({ selectedKeywordIds }) => {
    const handleClose = () => {
      setShowModal(false);
      setAction('');
    };

    const handleNoteUpdate = e => {
      e.preventDefault();
      const formData = new FormData(e.target);
      selectedKeywordIds.forEach(id => formData.append('keyword_ids[]', id));

      CallApi.put(
        '/search-query-keywords/bulk-update?type=note',
        formData
      ).then(json => {
        if (json.status == 200) {
          handleClose();
        } else {
          toast.warning('Failed to update note');
        }
      });
    };

    return (
      <>
        <Modal show={showModal} size="lg" centered onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {searchQueryContext?.isBulkSelectedAndUpdating &&
              searchQueryContext.bulkSelectAction ==
                'update-campaigns-adgroups-and-decide-as-positive'
                ? 'Edit Adgroup & Set as Positive'
                : 'Add Note'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-3">
            {searchQueryContext?.isBulkSelectedAndUpdating &&
              searchQueryContext.bulkSelectAction ==
                'update-campaigns-adgroups-and-decide-as-positive' && (
                <>
                  <h6>
                    <FontAwesomeIcon
                      transform="shrink-2"
                      className={`ms-1`}
                      icon="info-circle"
                    />
                    <span className="mx-1">
                      Selecting the new Campaign and Adgroup pair updates it.
                    </span>
                  </h6>
                  <ChangeCampaignAdgroupTable
                    setShow={setShowModal}
                    selectedKeywordIds={selectedKeywordIds}
                  />
                </>
              )}
            {searchQueryContext?.isBulkSelectedAndUpdating &&
              searchQueryContext.bulkSelectAction == 'update-note' && (
                <Form
                  id="search-query-keywords-bulk-note-update"
                  onSubmit={handleNoteUpdate}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="4">
                      Note{' '}
                      <LocalStorageTooltip identifier="upsert_budget_overview_note" />
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control as={'textarea'} name="note" type="text" />
                    </Col>
                  </Form.Group>
                </Form>
              )}
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              icon="times-circle"
              size="sm"
              onClick={handleClose}
              variant="falcon-light"
            >
              Cancel
            </IconButton>
            {searchQueryContext?.isBulkSelectedAndUpdating &&
              searchQueryContext.bulkSelectAction == 'update-note' && (
                <IconButton
                  icon="save"
                  size="sm"
                  form="search-query-keywords-bulk-note-update"
                  type="submit"
                >
                  Update
                </IconButton>
              )}
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const [action, setAction] = useState('');
  let selectedIds = [];

  for (const key in selectedRowIds) {
    if (Object.hasOwnProperty.call(selectedRowIds, key)) {
      page.map(row => {
        if (row.id == key) selectedIds.push(row.original.id);
      });
    }
  }

  const handleBulkSubmit = () => {
    const decidePayload = new FormData();

    selectedIds.forEach(id => decidePayload.append('keyword_ids[]', id));

    switch (action) {
      case '':
        toast.info('Please choose an option', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Flip
        });
        break;

      case 'decide-as-negative':
        CallApi.put(
          '/search-query-keywords/bulk-update?type=decide-as-negative',
          decidePayload
        ).then(json => {
          if (json?.status == 200) {
            resetGlobalFilterToLastQuery();
          }
        });
        break;

      default:
        setShowModal(true);
        setSearchQueryContext({
          ...searchQueryContext,
          isBulkSelectedAndUpdating: true,
          bulkSelectAction: action
        });
        break;
    }
  };

  return Object.keys(selectedRowIds).length > 0 ? (
    <div className={'d-flex ' + className}>
      <h5 className="fs-0 mb-0 mx-auto text-nowrap pe-2 pt-1">
        {Object.keys(selectedRowIds).length > 0
          ? 'You have selected ' + Object.keys(selectedRowIds).length + ' rows'
          : ''}
      </h5>
      <Form.Select
        size="sm"
        aria-label="Bulk actions"
        value={action}
        onChange={e => {
          setAction(e.target.value);
        }}
      >
        <option value="">Select an option</option>
        <option value="update-campaigns-adgroups-and-decide-as-positive">
          Edit Adgroup & Set as Positive
        </option>
        <option value="update-note">Add Note</option>
        <option value="decide-as-negative">Decide as Negative</option>
      </Form.Select>
      <Button size="sm" className="ms-2" onClick={handleBulkSubmit}>
        Apply
      </Button>
      {action ? <BulkSelectModal selectedKeywordIds={selectedIds} /> : null}
    </div>
  ) : null;
};
export default SearchQueryBulkSelect;
