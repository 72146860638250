import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'helpers/utils';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { STATUS } from './constants';

const StatusWithName = ({ status, name }) => {
  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip>{status}</Tooltip>}>
        <span>
          {name && <FontAwesomeIcon icon="circle" className={STATUS[status]} />}
        </span>
      </OverlayTrigger>{' '}
      {name}
    </>
  );
};

export default StatusWithName;
