import React, { Component } from 'react';
import Avatar from '../Avatar';

class NameWithIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
      icon: props.icon,
      rounded: props.rounded || false
    };
  }

  render() {
    return (
      <div className="fw-semi-bold d-flex align-items-center text-nowrap">
        {this.state.icon && (
          <img
            className={`me-1 ${
              this.state.rounded ? 'rounded-circle' : 'rounded'
            }`}
            src={this.state.icon}
            width="28"
            height="28"
          />
        )}
        {!this.state.icon && this.state.name && (
          <Avatar
            className="me-1"
            name={this.state.name}
            rounded="circle"
            size="l"
          />
        )}
        {this.state.name}
      </div>
    );
  }
}

export default NameWithIcon;
