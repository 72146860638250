import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Error403 = () => {
  return (
    <Row className="align-items-center">
      <Col lg={6} className="m-auto">
        <Card className="text-center">
          <Card.Body className="p-5">
            <div className="display-1 text-300 fs-error">403</div>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
              The page you're looking is unauthorized.
            </p>
            <hr />
            <p>
              Make sure the address is correct and you have access to that page.
              If you think this is a mistake,
              <a href="mailto:aida@digipartner.se" className="ms-1">
                contact us
              </a>
              .
            </p>
            <Link className="btn btn-primary btn-sm mt-3" to="/">
              <FontAwesomeIcon icon={faHome} className="me-2" />
              Take me home
            </Link>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Error403;
