import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ManualAddCompetitorModal from 'components/aida/accounts/wizard-sub-forms/ManualAddCompetitorModal';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { SeoWizardContext } from './Context';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import ReRunCompetitorsModal from './ReRunCompetitorsModal';
import SaveAsDraftWithNoteModal from './SaveAsDraftWithNoteModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Flip, toast } from 'react-toastify';

const SelectCompetitorsForm = () => {
  const columns = [
    {
      accessor: 'domain',
      Header: (
        <>
          Domain{' '}
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-domain'}
          />
        </>
      ),
      Cell: ({ cell }) => (
        <a
          href={`https://${cell.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}
        </a>
      )
    },
    {
      accessor: 'common_keywords',
      Header: (
        <>
          Common Keyword
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-common-keyword'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' },
      Cell: ({ cell }) => {
        let selectingCompetitorProgression = 0;

        if (cell.row.original?.common_keywords > 0) {
          selectingCompetitorProgression = 10;
        }

        if (cell.row.original?.common_keywords > 50) {
          selectingCompetitorProgression = 15;
        }

        if (cell.row.original?.common_keywords > 100) {
          selectingCompetitorProgression = 20;
        }

        if (cell.row.original?.common_keywords > 300) {
          selectingCompetitorProgression = 25;
        }
        return (
          <span
            title={`This competitor will increase the progress by ${selectingCompetitorProgression}%`}
          >
            {cell.value}
          </span>
        );
      }
    },
    {
      accessor: 'total_keywords',
      Header: (
        <>
          Total Keywords
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-total-keywords'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'relevance',
      Header: (
        <>
          Relevance
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-relevance'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' },
      Cell: ({ cell }) => {
        return (
          <span title={cell.value}>
            {+cell.value ? Math.round(cell.value * 100) + '%' : '-'}
          </span>
        );
      }
    },
    {
      accessor: 'points',
      Header: (
        <>
          Points
          <LocalStorageTooltip
            identifier={'table-select-competitors-column-points'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  ];
  const [initiallySelectedRowIds, setInitiallySelectedRowIds] = useState({});
  const [competitors, setCompetitors] = useState([]);
  const seoProjectContext = useContext(SeoWizardContext);
  const { currentSeoProject, nextStep, prevStep } = seoProjectContext;

  const CompetitorsQuery = useQuery(
    [
      'competitors',
      currentSeoProject?.main_information?.country_id,
      currentSeoProject?.main_information?.domain
    ],
    () =>
      CallApi.get(
        `/competitors/se-ranking-reports/competitors?country_id=${currentSeoProject?.main_information?.country_id}&domain=${currentSeoProject?.main_information?.domain}`,
        true
      ),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          const savedCompetitors = currentSeoProject?.competitors || [];

          const savedJsonCompetitors =
            savedCompetitors.map(
              competitorDomain =>
                json.data.find(
                  competitor => competitor.domain == competitorDomain
                ) || {
                  domain: competitorDomain,
                  common_keywords: '-',
                  total_keywords: '-',
                  relevance: '-',
                  points: '-'
                }
            ) || [];

          const allCompetitors = [
            ...savedJsonCompetitors,
            ...json.data
              .filter(competitor => competitor?.total_keywords > 30)
              .filter(item => !savedCompetitors.includes(item?.domain))
          ];

          let initialSelectedRowIndexes = {};

          const indexesOfPreviouslySelectedRows = allCompetitors
            ?.map((competitor, i) =>
              savedCompetitors.includes(competitor?.domain) ? i : null
            )
            ?.filter(index => index != null);

          indexesOfPreviouslySelectedRows.forEach(index => {
            initialSelectedRowIndexes[index] = true;
          });

          setInitiallySelectedRowIds(initialSelectedRowIndexes);

          setCompetitors(allCompetitors);
        }
      },
      enabled: !!(
        currentSeoProject?.main_information?.country_id &&
        currentSeoProject?.main_information?.domain
      )
    }
  );

  const UpdateSeoProjectDraftMutation = useMutation(
    'create-seo-project-draft',
    formData =>
      CallApi.put(`/seo-project-drafts/${currentSeoProject?.id}`, formData)
  );

  const CompetitorsMutation = useMutation(
    'compeittors-mutation',
    customDomain =>
      CallApi.get(
        `/competitors/se-ranking-reports/competitors?country_id=${currentSeoProject?.main_information?.country_id}&domain=${customDomain}`,
        true
      ),
    {
      onSuccess: (json, customDomain) => {
        const allCompetitors = [
          ...[
            {
              domain: customDomain,
              common_keywords: '-',
              total_keywords: '-',
              relevance: '-',
              points: '-'
            }
          ],
          ...json.data.filter(competitor => competitor?.total_keywords > 30)
        ];

        let initialSelectedRowIndexes = { 0: true };

        setInitiallySelectedRowIds(initialSelectedRowIndexes);

        setCompetitors(allCompetitors);
      }
    }
  );

  const saveCompetitors = selectedRows => {
    const formData = new FormData();

    formData.append(
      'competitors',
      JSON.stringify(selectedRows.map(row => row.original.domain))
    );

    UpdateSeoProjectDraftMutation.mutate(formData, {
      onSuccess: json => {
        if (json?.status == 200) {
          nextStep({
            currentSeoProject: json?.data
          });
        }
      }
    });
  };
  const apiCallInProcess =
    CompetitorsQuery.isFetching ||
    CompetitorsQuery.isRefetching ||
    CompetitorsMutation.isLoading;

  const getCurrentCompetitorProgress = selectedRows =>
    selectedRows?.reduce((totalProgress, nextCompetitor) => {
      if (nextCompetitor.original?.common_keywords > 300) {
        return totalProgress + 25;
      }

      if (nextCompetitor.original?.common_keywords > 100) {
        return totalProgress + 20;
      }

      if (nextCompetitor.original?.common_keywords > 50) {
        return totalProgress + 15;
      }

      if (
        nextCompetitor.original?.common_keywords > 0 ||
        nextCompetitor.original?.common_keywords == '-'
      ) {
        return totalProgress + 10;
      }
    }, 0);

  const CompetitorProgress = ({ selectedDataRows }) => {
    const currentCompetitorProgress =
      getCurrentCompetitorProgress(selectedDataRows);

    return (
      <Card>
        <Card.Body className="m-3">
          <Row className="align-items-center">
            <Col>
              <ProgressBar
                now={currentCompetitorProgress}
                label={`${
                  currentCompetitorProgress < 100
                    ? currentCompetitorProgress
                    : 100
                }%`}
              />
            </Col>
            <Col md="auto">
              <span
                className={
                  currentCompetitorProgress >= 100
                    ? 'text-success'
                    : 'text-dark'
                }
                title={currentCompetitorProgress}
              >
                {currentCompetitorProgress >= 100
                  ? 'Nice work!'
                  : 'Select Competitors'}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const NextPrevButtons = ({ selectedRowIds, data }) => {
    const [showTip, setShowTip] = useState(false);

    // Fix for issue with V7 of TanStack Table, see https://github.com/TanStack/table/issues/2210
    let selectedFlatRows = Object.keys(selectedRowIds).map(rowId => ({
      original: data[rowId]
    }));
    const isNextStepButtonDisabled = Object.keys(selectedFlatRows).length < 4;
    const currentCompetitorProgress =
      getCurrentCompetitorProgress(selectedFlatRows);

    return (
      <ButtonGroup className="mt-4">
        <Button
          variant="outline-primary"
          className="rounded-pill"
          onClick={prevStep}
        >
          <FontAwesomeIcon icon={'arrow-left'} /> Previous
        </Button>
        <SaveAsDraftWithNoteModal
          dataToBeDrafted={selectedFlatRows.map(row => row.original.domain)}
        />
        <SweetAlert
          btnSize="md"
          showCancel
          show={showTip}
          confirmBtnText="Proceed"
          cancelBtnText="Go back"
          title={<h4>Tip for Best Results!</h4>}
          onConfirm={() => saveCompetitors(selectedFlatRows)}
          onCancel={() => setShowTip(false)}
          openAnim={{ name: 'fade in' }}
          closeAnim={{ name: 'fade out' }}
        >
          <div className="py-3">
            <p className="text-wrap mb-2 text-start">
              You haven't filled the progress bar to 100%. For the best possible
              outcome, we recommend selecting more competitors.
            </p>
            <p className="text-wrap text-start">
              Would you still like to proceed with your current selection?
            </p>
          </div>
        </SweetAlert>
        <OverlayTrigger
          placement={'top'}
          overlay={
            isNextStepButtonDisabled ? (
              <Tooltip>at least 4 competitors must be selected</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              variant="outline-primary"
              className="rounded-pill ms-2"
              disabled={isNextStepButtonDisabled}
              onClick={() =>
                currentCompetitorProgress < 100
                  ? setShowTip(true)
                  : saveCompetitors(selectedFlatRows)
              }
            >
              {UpdateSeoProjectDraftMutation.isLoading ? (
                <>
                  Updating draft... <FontAwesomeIcon icon={'spinner'} spin />
                </>
              ) : (
                <>
                  Next Step: Keywords <FontAwesomeIcon icon={'arrow-right'} />
                </>
              )}
            </Button>
          </span>
        </OverlayTrigger>
      </ButtonGroup>
    );
  };

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={competitors || []}
        sortable
        pagination
        perPage={25}
        selection
        uncheckAllButton={false}
        showBulkSelectRowCheckbox={false}
        initialSelectedRowIds={initiallySelectedRowIds}
        customRowSelectionFunction={(
          e,
          cell,
          selectedFlatRows,
          selectedRowIds,
          selectedDataRows
        ) => {
          const checking = e.target.checked;

          let selectingCompetitorProgression = 0;

          if (
            cell.row.original?.common_keywords > 0 ||
            cell.row.original?.common_keywords == '-'
          ) {
            selectingCompetitorProgression = 10;
          }

          if (cell.row.original?.common_keywords > 50) {
            selectingCompetitorProgression = 15;
          }

          if (cell.row.original?.common_keywords > 100) {
            selectingCompetitorProgression = 20;
          }

          if (cell.row.original?.common_keywords > 300) {
            selectingCompetitorProgression = 25;
          }

          const currentCompetitorProgress =
            getCurrentCompetitorProgress(selectedDataRows);

          if (checking && currentCompetitorProgress >= 100) {
            toast.error('You cannot select more competitors.', {
              position: 'top-center',
              transition: Flip,
              autoClose: 2000
            });
            return;
          }

          cell.row.toggleRowSelected(checking);

          if (
            checking &&
            currentCompetitorProgress + selectingCompetitorProgression >= 100
          ) {
            toast.success('🎉 Competitors Selected!', {
              position: 'top-center',
              transition: Flip,
              autoClose: 2000
            });
          }
        }}
      >
        <CompetitorProgress table />

        <Card className="mt-5">
          <Card.Body className="m-3">
            <Row className="align-items-space-between mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>

              <Col>
                <TopSelectedRowsCounter table />
              </Col>
              <Col xs="auto" className="pe-0">
                <ReRunCompetitorsModal
                  rerunCompetitors={customDomain => {
                    CompetitorsMutation.mutate(customDomain);
                  }}
                />
              </Col>
              <Col xs="auto">
                <ManualAddCompetitorModal
                  disabled={apiCallInProcess}
                  setCompetitors={setCompetitors}
                  competitors={competitors}
                  setInitiallySelectedRowIds={setInitiallySelectedRowIds}
                  table
                  variant="outline-primary"
                  className="rounded-pill"
                  cantSelectMoreThan100Percent={true}
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              loadingText={'Please wait, we are fetching your competitors...'}
              apiCallInProcess={apiCallInProcess}
            />
            <AdvanceTableFooter
              showSelectedRowCount
              rowsPerPageOptions={[25, 50, 75, 100]}
              table
              className="mt-3 mx-3"
              rowCount={competitors?.length}
              rowInfo
              navButtons
              rowsPerPageSelection
            />
            <Flex justifyContent="center">
              <NextPrevButtons table data={competitors} />
            </Flex>
          </Card.Body>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

const TopSelectedRowsCounter = ({ selectedRowIds }) => {
  return (
    <h6 className="mt-1 mb-0 text-center">
      {Object.keys(selectedRowIds).length > 0
        ? 'You have selected ' + Object.keys(selectedRowIds).length + ' rows'
        : ''}
    </h6>
  );
};

export default SelectCompetitorsForm;
