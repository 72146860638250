import useUrl from 'hooks/useUrl';
import React, { useEffect, useState } from 'react';
import CallApi from './CallApi';

const StaticPage = ({ identifier }) => {
  const url = useUrl();

  const [pageContent, setPageContent] = useState();
  useEffect(() => {
    CallApi.get(`/static-page/${url.get('id') || identifier}`, false).then(
      json => setPageContent(json.data.content)
    );
    return () => {};
  }, []);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: pageContent
      }}
    ></div>
  );
};

export default StaticPage;
