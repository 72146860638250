import IconButton from 'components/common/IconButton';
import React, { useContext } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import { searchQueryKeywordsContext } from '../SearchQueryKeywords';
import ChangeCampaignAdgroupTable from './ChangeCampaignAdgroupTable';

const ChangeCampaignAdgroupModal = ({ show, setShow }) => {
  const { searchQueryContext, setSearchQueryContext } = useContext(
    searchQueryKeywordsContext
  );
  return (
    <Modal
      show={show}
      size="xl"
      onHide={() => {
        setShow(false);
        setSearchQueryContext({
          ...searchQueryContext,
          adgroupChangeableKeyword: {}
        });
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Changing Campaign and Adgroup for{' '}
          <b>
            {searchQueryContext.adgroupChangeableKeyword
              ? searchQueryContext.adgroupChangeableKeyword.keyword
              : ''}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ChangeCampaignAdgroupTable setShow={setShow} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeCampaignAdgroupModal;
